import React, { Component } from 'react';
import IceLand from 'styles/icons/icons/Ice-hockey-rink.png';
import PropTypes from 'prop-types';
import styles from './lineup-widget.module.less';
import JErsey from 'components/block-elements/jersey-element/jersey-element';
import TeamLogoImage from 'components/block-elements/team-logo-image/team-logo-image';
import staticJerseyColors from 'static/JerseyColorsIce.json';
import UiBtn from 'components/block-elements/ui-btn/ui-btn';
import EventAggregator from 'components/helpers/event-aggregator';

class LineupWidget extends Component {
  static propTypes = {
    homeTeamId: PropTypes.number.isRequired,
    guestTeamId: PropTypes.number.isRequired,
    homeTeamName: PropTypes.string.isRequired,
    guestTeamName: PropTypes.string.isRequired,
    maxLines: PropTypes.number.isRequired,
    homeTeam: PropTypes.array.isRequired,
    guestTeam: PropTypes.array.isRequired
  };

  constructor(props) {
    
    super(props);
    this.homeTeam = props.homeTeam;
    this.guestTeam = props.guestTeam;
    this.maxLines = props.maxLines;
    this.data = this.props;

    const Line1 = 2;
    const Line2 = 3;
    const Line3 = 4;
    const Line4 = 5;

    this.buttonAlterantives = [
      {
        buttonText: "1:a Lineup",
        value: Line1,
      },
      {
        buttonText: "2:a Lineup",
        value: Line2,
      },
      {
        buttonText: "3:a Lineup",
        value: Line3,
      },
      {
        buttonText: "4:a Lineup",
        value: Line4,
      },
    ];
    this.state = {
      lines: this.maxLines ? Array.from(Array(this.maxLines - 1).keys()) : null,
      line: 2,
      homeTeamCoach: '',
      guetTeamCoach: '',
      HOMEJersey: "#000000",
      HOMEStripesandCollar: "#C8242B",
      HOMEStripesEXTRA: "#E8CB2D",
      HOMENumber: "#E8CB2D",
      HOMENumberOutline: null,
      AWAYJersey: "#FFFFFF",
      AWAYStripesandCollar: "#C8242B",
      AWAYStripesEXTRA: "#E8CB2D",
      AWAYNumber: "#000000",
      AWAYNumberOutline: null,
      activeTab: Line1,
    }
  }

  componentDidMount() {
    this.props.homeTeam.map(el => {
      if(el.participant.type == 'coach') {
        return this.setState({homeTeamCoach: el.participant.name});
      }
    })

    this.props.guestTeam.map(el => {
      if(el.participant.type == 'coach') {
        return this.setState({guetTeamCoach: el.participant.name});
      }
    })

    staticJerseyColors.Data.map(jer => {
      if(this.props.homeTeamName.indexOf(jer['Team name']) != -1) {
        this.setState({
          HOMEJersey: jer['HOME Jersey'],
          HOMEStripesandCollar: jer['HOME Stripes and Collar'],
          HOMEStripesEXTRA: jer['HOME Stripes EXTRA'],
          HOMENumber: jer['HOME Number'],
          HOMENumberOutline: jer['HOME Number Outline']
        })
      }
    })

    staticJerseyColors.Data.map(jer => {
      if(this.props.guestTeamName.indexOf(jer['Team name']) != -1) {
        this.setState({
          AWAYJersey: jer['AWAY Jersey'],
          AWAYStripesandCollar: jer['AWAY Stripes and Collar'],
          AWAYStripesEXTRA: jer['AWAY Stripes EXTRA'],
          AWAYNumber: jer['AWAY Number'],
          AWAYNumberOutline: jer['AWAY Number Outline']
        })
      }
    })
  }

  homeTeamPosition() {
      this.homeTeam.map(player => {
      return <li>{player.pos}</li>
    });
  }

  setLine(val) {
    return this.setState({ line: val })
  }

  normalizeFullName(name = '') {
    let index = name.indexOf(' ');
    let shortName = name.substr(index, name.length - 1);
    return `${name[0]} ${shortName}`;
  }

  isTimeValid() {
    let date = new Date(this.props.scheduled);
    let offset = new Date().getTimezoneOffset() / 60;

    date.setHours(date.getHours() - offset - 1);
  
    if(new Date() >= new Date(date)) {
      return true;
    } else {
      return false;
    }
  }

  isLineupsValid() {
    return (this.props.homeTeam && this.props.guestTeam && this.props.homeTeam.length > 5 && this.props.guestTeam.length > 5 && this.isTimeValid());
  }


  render() {
    const { homeTeam, guestTeam, homeTeamId, guestTeamId, homeTeamName, guestTeamName } = this.props;
    return (
      <div className={styles.mainContainerFluid}>
        <div style={{maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto'}}>
          <h4 className={[styles.title].join(' ')}>Lineups</h4>
        </div>
        {<div style={{marginLeft: 'auto', marginRight: 'auto', maxWidth: '600px'}}>
          {
          this.isLineupsValid() ?
            <div className={styles.lineButtonsContainer}>
                {this.buttonAlterantives.map((button, index) => (
                  <UiBtn
                    key={index}
                    label={button.buttonText}
                    onClick={() => {this.setState({ activeTab: button.value }); EventAggregator.publish("trackEvent", { category: 'Tournament tab', action: this.tournamentName, label: button.buttonText });}}
                    className={[
                      this.state.activeTab === button.value ? styles.activeButton : styles.inactiveButton,
                      index === 0 && styles.firstButton, styles.alternativeButton,
                    ].join(' ')}
                  />
                ))}
            </div> : null
          }
            <div className={styles.homeTeamLogo}>
              <div style={{marginRight: '10px'}}>
              <TeamLogoImage competitorId={homeTeamId} className={styles.teamLogo}></TeamLogoImage>
              </div>
              <div>
              <div>
              <span>{homeTeamName}</span>
              </div>
              {
                this.isLineupsValid() ?
                <div className={styles.coachName}>
                  COACH: <span className={styles.coachNameSpan}>{this.state.homeTeamCoach}</span>
                </div> : null
              }
            </div>
            </div>
          <div className={styles.mainBlock}>
            <div style={{backgroundImage: `url(${IceLand})` }} className={`${styles.mainContainer} ${!this.isLineupsValid() ? styles.errorContainer : null}`}>
              { this.isLineupsValid() ?
                <div>
                <div className={styles.landHomeContainer}>
                  {homeTeam.map(pl =>
                    pl.position === 11 ? <div className={styles.homeKeeper}>
                              <JErsey jerseyColor={this.state.HOMEJersey}
                                jerseyStrypeColor={this.state.HOMEStripesandCollar}
                                jerseyStrypeExtraColor={this.state.HOMEStripesEXTRA}
                                width='115' 
                                height='115' 
                                peayerNumber={pl.jerseyNumber} 
                                peayerFullName={`${pl.name?.substring(0, 1)}. ${pl.name?.split(' ')[1]}`} 
                                jerseyScoreColor={this.state.HOMENumber}
                                jerseyScoreBorderColor={this.state.HOMENumberOutline}/>
                    </div> : ""
                  )}

                  <div className={styles.homeGuarders}>
                    {homeTeam.map(pl =>
                      (pl.position == `${this.state.activeTab}2` || pl.position == `${this.state.activeTab}3`) ? 
                        <JErsey jerseyColor={this.state.HOMEJersey}
                        jerseyStrypeColor={this.state.HOMEStripesandCollar}
                        jerseyStrypeExtraColor={this.state.HOMEStripesEXTRA}
                        width='115' 
                        height='115' 
                        peayerNumber={pl.jerseyNumber} 
                        peayerFullName={`${pl.name?.substring(0, 1)}. ${pl.name?.split(' ')[1]}`} 
                        jerseyScoreColor={this.state.HOMENumber}
                        jerseyScoreBorderColor={this.state.HOMENumberOutline}/> 
                      : null
                    )}
                  </div>
                  <div className={styles.homeAtackers}>
                    {homeTeam.map(pl =>
                      (pl.position == `${this.state.activeTab}4` || pl.position == `${this.state.activeTab}5` || pl.position == `${this.state.activeTab}6`) ? 
                      <div className={styles.jerseyAttackers}>
                        <JErsey jerseyColor={this.state.HOMEJersey}
                          jerseyStrypeColor={this.state.HOMEStripesandCollar}
                          jerseyStrypeExtraColor={this.state.HOMEStripesEXTRA}
                          width='115' 
                          height='115' 
                          peayerNumber={pl.jerseyNumber} 
                          peayerFullName={`${pl.name?.substring(0, 1)}. ${pl.name?.split(' ')[1]}`} 
                          jerseyScoreColor={this.state.HOMENumber}
                          jerseyScoreBorderColor={this.state.HOMENumberOutline}/>
                      </div> 
                      : null
                    )}
                  </div>
                </div>

                <div className={styles.landGuestContainer}>
                  <div className={styles.guesAtackers}>
                    {guestTeam.map(pl =>
                      (pl.position == `${this.state.activeTab}4` || pl.position == `${this.state.activeTab}5` || pl.position == `${this.state.activeTab}6`) ? 
                      <div className={styles.jerseyGuestAttackers}>
                        <JErsey jerseyColor={this.state.AWAYJersey}
                          jerseyStrypeColor={this.state.AWAYStripesandCollar}
                          jerseyStrypeExtraColor={this.state.AWAYStripesEXTRA}
                          width='115' 
                          height='115' 
                          peayerNumber={pl.jerseyNumber} 
                          peayerFullName={`${pl.name?.substring(0, 1)}. ${pl.name?.split(' ')[1]}`} 
                          jerseyScoreColor={this.state.AWAYNumber}
                          jerseyScoreBorderColor={this.state.AWAYNumberOutline}/>
                      </div> : null
                    )}
                  </div>

                  <div className={styles.guestGuarders}>
                    {guestTeam.map(pl =>
                      (pl.position == `${this.state.activeTab}2` || pl.position == `${this.state.activeTab}3`) ? 
                      <JErsey jerseyColor={this.state.AWAYJersey}
                      jerseyStrypeColor={this.state.AWAYStripesandCollar}
                      jerseyStrypeExtraColor={this.state.AWAYStripesEXTRA}
                      width='115' 
                      height='115' 
                      peayerNumber={pl.jerseyNumber} 
                      peayerFullName={`${pl.name?.substring(0, 1)}. ${pl.name?.split(' ')[1]}`} 
                      jerseyScoreColor={this.state.AWAYNumber}
                      jerseyScoreBorderColor={this.state.AWAYNumberOutline}/>
                      : null
                    )}
                  </div>
                  
                    <div className={styles.guestKeeper}>
                    {guestTeam.map(pl =>
                      pl.position == '11' ?
                        <JErsey jerseyColor={this.state.AWAYJersey}
                        jerseyStrypeColor={this.state.AWAYStripesandCollar}
                        jerseyStrypeExtraColor={this.state.AWAYStripesEXTRA}
                        width='115' 
                        height='115' 
                        peayerNumber={pl.jerseyNumber} 
                        peayerFullName={`${pl.name?.substring(0, 1)}. ${pl.name?.split(' ')[1]}`} 
                        jerseyScoreColor={this.state.AWAYNumber}
                        jerseyScoreBorderColor={this.state.AWAYNumberOutline}/>
                      : null
                    )}
                    </div>
                  </div>
                </div> : <div className={styles.errorMessage}><span style={{marginTop: 'auto', marginBottom: 'auto'}}>Lineups presenteras när de släppts, c:a en timme före matchstart</span></div>
              }
            </div>
          </div>

          <div className={styles.guestTeamLogo}>
            <div>
              <div>
              <span>{guestTeamName}</span>
              </div>
              { this.isLineupsValid() ?
                <div className={styles.coachName}>
                  COACH: <span className={styles.coachNameSpan}>{this.state.guetTeamCoach}</span>
                </div> : null
              }
            </div>
            <div style={{marginLeft: '10px'}}>
            <TeamLogoImage competitorId={guestTeamId} className={styles.teamLogo}></TeamLogoImage>
            </div>
          </div>

        </div>}

      </div>
    );
  }
};

export default LineupWidget;
